import "../scss/app.scss";
import onDomReady from './global/domReady.js';

//
// Dom Ready
//---------------------------------------------------

(function () {
  if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
    onDomReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDomReady)
  }
})();
import "./map/map";
import "./global/photoswipe";
