// MAPBOX BELOWss
import geoJSON from "../map.areas.json";

import mapboxgl from "mapbox-gl";
import { map } from "./map.config.js";
import { addAllItems } from "./map.source";
import vectorTiles from "../../../mapbox/tile.json"

if (map) {
  // Add zoom and rotation controls to the map.
  const zoomControls = new mapboxgl.NavigationControl();
  if (document.getElementById('map')) {
    //map.addControl(zoomControls);
  }
  map.on("load", function () {

      map.resize();
      // Add local Vector Tileset
      map.addSource("states", {
        type: "vector",
        data: vectorTiles
      });


      map.addSource("WestEastBerlin", {
        type: "geojson",
        data: geoJSON
      });

      // Find the index of the first symbol layer in the map style
      const
        firstSymbolId = map.getStyle().layers.find(layer => layer.type === "symbol");
      addAllItems(firstSymbolId);

      map.on("click", (e) => {
        document.getElementById('showlist').checked = false;

      })
      map.getContainer().classList.add('is-loaded');
      // Create a GeoJSON source with an empty lineString.
      let emptyGeoJson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              coordinates: [[0, 0]]
            }
          }
        ]
      };
    }
  );

}
