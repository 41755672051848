/**
 * Marker Template
 * @param {obj} data - property data/json
 */
function markerTmpl (data) {
  const { name, link, description, image, published } = data;
  let linkTarget = '_self';

  let template = `
    <div class="a-popup">
        <div class="a-popup__wrapper">
          <a href="${link}" target="${linkTarget}" title="Read ${name}">
            <div class="a-popup__header">
              <h3 class="a-popup__headline theta">${name}</h3>
                <div class="a-popup__media">
                ${image !== "null" ? `<img src="${image}" title="Portrait image of ${name}" alt="Portrait image of ${name}">` : ''}
                </div>
            </div>
            ${description !== "null" ? `<p class="a-popup__excerpt">${description}</p>` : ''}
            <div class="a-popup__footer">
              <span class="a-popup__cta">Read</span>
            </div>
          </a>
        </div>
    </div>
  `
  // Show something different when the interview is not published yet (not ready)
  if (published !== true) {
    //link = 'https://berlin.us5.list-manage.com/subscribe/post?u=4a04f04a8b5aae84a0c7388dd&amp;id=31666bf0f5';
    linkTarget = '_blank';

    template = `
    <div class="a-popup">
        <div class="a-popup__wrapper">
          
            <div class="a-popup__header">
              <h3 class="a-popup__headline theta">${name}</h3>
                <div class="a-popup__media">
                  ${image !== "null" ? `<img src="${image}" title="Portrait image of ${name}" alt="Portrait image of ${name}">` : ''}
                </div>
            </div>
            <p class="a-popup__notification"><strong>Relato en post-producción.</strong> ¡Inscríbete y no te pierdas su estreno!</p>
            <p class="a-popup__excerpt"> ${description}</p>
             
            <div class="a-popup__footer">
            
              <div class="m-newsletter m-newsletter--popup">
                <div id="mc_embed_signup">
                  <div id="mce-responses" class="clear">
                      <div class="response" id="mce-error-response" style="display:none"></div>
                      <div class="response" id="mce-success-response" style="display:none"></div>
                  </div>
                  <form action="https://berlin.us5.list-manage.com/subscribe/post?u=4a04f04a8b5aae84a0c7388dd&amp;id=31666bf0f5" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                      <div id="mc_embed_signup_scroll">
                          <div class="mc-field-group">
                              <input type="email" value="" name="EMAIL" placeholder="E-Mail Address" class="required email" id="mce-EMAIL">
                          </div>
                          <input type="hidden" name="INTERVIEW" value="${name}">

                          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                          <div style="position: absolute; left: -5000px;" aria-hidden="true">
                              <input type="text" name="b_4a04f04a8b5aae84a0c7388dd_31666bf0f5" tabindex="-1" value="">
                          </div>
                          <div class="clear">
                              <input type="submit" value="Get notified" name="subscribe" id="mc-embedded-subscribe" class="button">
                          </div>
                      </div>
                  </form>
                </div>
              </div>
            </div>
        </div>
    </div>
  `
  }

  return template;
}

export default markerTmpl
