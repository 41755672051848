import mapboxgl from 'mapbox-gl';
import { map } from "./map.config.js";
import markerTmpl from "./map.popup.tmpl.js";
import { sourceName } from "./map.source";

let hoveredStateId = null;

/**
 * Hide open Popups when zoomed out
 * @param {object} popup - element to hide
 */
const hideOpenPopupsOnZoomedOut = popup => {
  map.on("zoom", e => {
    if (map.getZoom() < 8) {
      popup.remove();
    } else if (map.getZoom() > 9) {
      // Maybe reopen previous popup
      //buildPopup(e);

    }
  });
};
/**
 * Shows the Popup on Click
 * @param {string} idName - unique marker ids
 * @param {string } sourceName - name of source Data for map
 */
export const showPopup = (idName, sourceName) => {

  // Change Cursor
  map.on("mouseenter", idName, function (e) {
    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = "pointer";

    if (e.features.length > 0) {

      // Remove Hover State on previoused hovered Icon
      if (hoveredStateId) {
        map.setFeatureState({ source: sourceName, id: hoveredStateId }, { hover: false });
      }
      hoveredStateId = e.features[0].id;
      // @ToDo Add Hover Effect
      //

      // ITS NOT FUCKING WORKING
      //map.setPaintProperty(`marker_${hoveredStateId}`, 'circle-radius', ['match', ['get', 'id'], `marker_${hoveredStateId}`, 11, 20]);

      //map.setPaintProperty(`marker_${hoveredStateId}`, 'circle-radius', 10);
      map.setFeatureState({ source: sourceName, id: hoveredStateId }, { hover: true });
    }
  });
  map.on('mouseleave', idName, function (e) {
    if (hoveredStateId) {

      map.setFeatureState(
        { source: sourceName, id: hoveredStateId },
        { hover: false }
      );
    }
    hoveredStateId = null;
  });
  // Show Popups
  map.on("click", idName, function (e) {
    const popup = e;

    // map.flyTo({
    //   center: e.features[0].geometry.coordinates,
    //   speed: 0.3,
    // });

    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = "pointer";
    //
    // map.flyTo({
    //   center: popup.features[0].geometry.coordinates,
    //   speed: 0.3,
    // });

    buildPopup(popup);

  });
}

/**
 * When click on Marker Icon close the open Popup
 * @param {object} popup
 */
const closeOpenPopupOnClick = (popup) => {
  map.on("click", function (e) {
    map.getCanvas().style.cursor = "";
    popup.remove();
  });
};
const buildPopup = (popupEvent, sourceName) => {
  const features = popupEvent.features[0];
  const geometry = features.geometry;
  const properties = features.properties;
  let data = {
    coordinates: geometry.coordinates.slice(),
    name: properties.name,
    description: properties.excerpt,
    image: properties.image,
    link: properties.path,
    published: properties.published,
  };

  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.
  while (Math.abs(popupEvent.lngLat.lng - data.coordinates[0]) > 180) {
    data.coordinates[0] += popupEvent.lngLat.lng > data.coordinates[0] ? 360 : -360;
  }
  // Populate the popup and set its coordinates
  // based on the feature found.
  const tmpl = markerTmpl(data);

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: true,
    className: "a-popup",
    maxWidth: "300px"
  }).setLngLat(data.coordinates).setHTML(tmpl).addTo(map);
  //console.log(popup);

  // Add Close Listener
  hideOpenPopupsOnZoomedOut(popup);
  closeOpenPopupOnClick(popup);
};

