import { map, mapType } from "./map.config.js";
import { showPopup } from "./map.popup.js";
import mapboxgl from "mapbox-gl";

export let sourceName;
const addEastWestBerlinArea = (firstSymbolId) => {
  // Add East + West Berlin Layers
  map.addLayer(
    {
      id: "EastWestBerlin",
      type: "fill",
      source: "WestEastBerlin",
      paint: {
        "fill-opacity": ["case",
          ["boolean", ["feature-state", "hover"], false],
          0.2,
          ["get", "fill-opacity"]
        ],
        "fill-opacity-transition": { duration: 300 },

        "fill-color": ["case",
          ["boolean", ["feature-state", "hover"], false],
          "#7A3919",
          ["get", "fill-color"]
        ],
        "fill-color-transition": { duration: 300 }
      },
      filter: ["==", "$type", "Polygon"]
    },
    firstSymbolId
  );

};

const addBerlinWallLine = () => {
  // Add Berlin Wall Line
  map.addLayer({
    id: "BerlinWall",
    type: "line",
    source: "WestEastBerlin",
    paint: {
      "line-color": ["case",
        ["boolean", ["feature-state", "hover"], false],
        "#7A3919",
        ["get", "stroke-color"]
      ],
      "line-opacity": ["case",
        ["boolean", ["feature-state", "hover"], false],
        1,
        ["get", "stroke-opacity"]
      ],
      "line-width": ["get", "stroke-width"],
      "line-opacity-transition": { duration: 300 },
      "line-color-transition": { duration: 300 },

    },
    transition: {
      "duration": 300,
      "delay": 0
    },
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    filter: ["==", "$type", "LineString"]
  });

  let hoveredStateId = null;
  map.on("mousemove", "BerlinWall", function (e) {
    map.getCanvas().style.cursor = "pointer";

    if (e.features.length > 0) {
      if (hoveredStateId) {
        map.setFeatureState({ source: 'WestEastBerlin', id: hoveredStateId }, { hover: false });
      }
      hoveredStateId = e.features[0].id;
      map.setFeatureState({ source: 'WestEastBerlin', id: hoveredStateId }, { hover: true });

    }
  });

  // Add Link redirection
  map.on("click", "BerlinWall", function (e) {
    map.getCanvas().style.cursor = "pointer";

    if (e.features.length > 0) {
      const url = e.features[0].properties.path;
      window.location.href = url;
    }
  });

  // When the mouse leaves the state-fill layer, update the feature state of the
  // previously hovered feature.
  map.on("mouseleave", "BerlinWall", function () {
    map.getCanvas().style.cursor = "";

    if (hoveredStateId) {
      map.setFeatureState({ source: 'WestEastBerlin', id: hoveredStateId }, { hover: false });
    }
    hoveredStateId = null;
  });

  // Change Cursor
  map.on("mouseenter", "BerlinWall", function (e) {
    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = "pointer";

  });
};

const addAreas = () => {
  addEastWestBerlinArea();
};

const addLines = () => {
  addBerlinWallLine();
};


const addMarkers = () => {

  if (mapType != "static") {

    // Add Labels for Areas
    map.addLayer({
      id: "areaLabels",
      type: "symbol",
      source: "WestEastBerlin",
      minzoom: 8,
      paint: {
        "text-color":
          ["case",
            ["boolean", ["feature-state", "hover"], false],
            "#7A3919",
            "#AB633F"
          ]
      },
      layout: {
        "text-field": [
          "format",
          ["get", "name"],
          {
            "text-font": ["literal", ["Noto Sans Bold"]]
          }
        ],
        "text-size": {
          "stops": [
            [8, 10],
            [9, 12],
            [10, 14]
          ]
        },
        "text-offset": [0, 0.6],
        "text-anchor": "top",
        "text-transform": "uppercase"
      },
      filter: ["==", "category", "areaLabel"]
    });


    // Hover for Areas
    let hoveredStateId = null;
    map.on("mousemove", "areaLabels", function (e) {
      map.getCanvas().style.cursor = "pointer";

      if (e.features.length > 0) {
        switch (e.features[0].id) {
          case 4:
            hoveredStateId = 2;
            map.setFeatureState({ source: 'WestEastBerlin', id: 4 }, { hover: true });

            break;
          case 5:
            hoveredStateId = 3;
            map.setFeatureState({ source: 'WestEastBerlin', id: 5 }, { hover: true });
            break;
          default:
            hoveredStateId = null
        }

        if (hoveredStateId) {
          map.setFeatureState({ source: 'WestEastBerlin', id: hoveredStateId }, { hover: false });
        }

        map.setFeatureState({ source: 'WestEastBerlin', id: hoveredStateId }, { hover: true });
      }
    });

    // When the mouse leaves the state-fill layer, update the feature state of the
    // previously hovered feature.
    map.on("mouseleave", "areaLabels", function () {
      map.getCanvas().style.cursor = "";

      if (hoveredStateId) {
        map.setFeatureState({ source: 'WestEastBerlin', id: 4 }, { hover: false });
        map.setFeatureState({ source: 'WestEastBerlin', id: 5 }, { hover: false });
        map.setFeatureState({ source: 'WestEastBerlin', id: hoveredStateId }, { hover: false });
      }
      hoveredStateId = null;
    });

    // Add Link redirection
    map.on("click", "areaLabels", function (e) {
      map.getCanvas().style.cursor = "pointer";
      if (e.features.length > 0) {
        const url = e.features[0].properties.path;
        window.location.href = url;
      }
    });
  }
  const metaLang = document.querySelectorAll('meta[name="lng"]');
  const metaLat = document.querySelectorAll('meta[name="lat"]');
  const pageName = document.querySelectorAll('meta[property="og:title"]')[0].content;

  // Static Pages
  if (metaLang.length > 0 && metaLat.length > 0) {
    const pageCoords = [
      metaLat[0].content,
      metaLang[0].content,
    ];

    // Create a GeoJSON source with an empty lineString.
    const pageMarker = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            shape: "Marker",
            name: pageName,
          },
          geometry: {
            type: "Point",
            coordinates: [pageCoords]
          }
        }
      ]
    };

    // For Interview Static Sites
    map.addSource("pageMarker", {
      type: "geojson",
      data: pageMarker,
      generateId: true
    });

    // Add Interview Marker
    map.addLayer({
      id: "pageMarker",
      type: "circle",
      source: "pageMarker",
      paint: {
        'circle-radius': {
          'base': 1.75,
          'stops': [
            [12, 2],
            [15, 2.5]
          ]
        },
        "circle-radius-transition": { duration: 300 },
        "circle-color": "#B42222"
      }
    });
    // create a HTML element for each feature
    const el = document.createElement('div');
    el.className = 'a-marker';

    // add marker to map
    new mapboxgl.Marker(el).setLngLat(pageCoords).addTo(map);

    // Set Center
    map.flyTo({ center: pageCoords })

  } else {


    // Import JSON
    importUrl.map((importUrl) => {

      const req = new XMLHttpRequest();
      req.overrideMimeType("application/json");
      req.open('GET', importUrl, true);
      req.onload = function (xhr) {
        const jsonResponse = JSON.parse(req.responseText);
        const responseURL = req.responseURL;
        // Build sourceName
        const filename = responseURL.substring(0, responseURL.lastIndexOf(".")).substring(responseURL.lastIndexOf('/') + 1);
        sourceName = filename + "Json";

        // Load MarkersJson
        map.addSource(sourceName, {
          type: "geojson",
          data: jsonResponse,
          generateId: false
        });


        const tagArray = jsonResponse.features.reduce((result, marker) => {
          if (marker.properties["tags"] != null) {
            result.push(marker.properties["tags"])
          }
          return result;
        }, []);

        const tagArrayUnique = new Set(tagArray);

        const filterList = document.querySelectorAll('.o-filterlist ul')[0];
        const filterListItem = document.createElement('li');


        // @ToDo: Load tags.json and display the information on the map. This ↓ is just for adding the filterlist (the ui to filter the tags) dynamically.
        // Add Markers for each Tag
        // [...tagArrayUnique].map(marker => {
        //   return;
        //   // Generate a layerID for each Tag
        //   let layerID;
        //   marker.map(tag => {
        //     layerID = "tag-" + tag.replace(/\s/g, '');
        //   });
        //
        //   // Add a layer for this marker if it hasn't been added already.
        //   if (!map.getLayer(layerID)) {
        //     // @ToDo: ↓ its not working, i don’t know why tho
        //     // map.addLayer({
        //     //   id: layerID,
        //     //   type: "circle",
        //     //   source: sourceName,
        //     //   paint: {
        //     //     "circle-radius": 29,
        //     //     "circle-color": "#3BB430"
        //     //   },
        //     //
        //     // });
        //
        //
        //     // Add checkbox and label elements for the layer.
        //     const input = document.createElement('input');
        //     input.type = 'checkbox';
        //     input.id = layerID;
        //     input.checked = true;
        //     filterListItem.appendChild(input);
        //     // When the checkbox changes, update the visibility of the layer.
        //
        //     input.addEventListener('change', function (e) {
        //       map.setLayoutProperty(
        //         layerID,
        //         'visibility',
        //         e.target.checked ? 'visible' : 'none'
        //       );
        //     });
        //     const label = document.createElement('label');
        //     label.setAttribute('for', layerID);
        //
        //     label.textContent = marker;
        //     filterListItem.appendChild(label);
        //     filterList.appendChild(filterListItem);
        //
        //
        //   }
        // })
        map.addLayer({
          id: "marker",
          type: "circle",
          source: sourceName,
          minzoom: 8.5,
          paint: {
            'circle-radius': {
              'base': 5,
              'stops': [
                [11, 7],
                [12, 10],
                [13, 20],
                [14, 30],
                [15, 50]
              ]
            },
            "circle-color":
              [
                "case",
                ["==", ['get', 'published'], true], "#B42222",
                ["==", ['get', 'published'], false], "#B07070",
                "#B42222",
              ],
            "circle-radius-transition":
              {
                duration: 3000
              }
            ,
          },

          transition: {
            "duration":
              3000,
            "delay":
              0
          }
          ,
          filter: ["all", ["!=", "category", "areaLabel"], ["==", "$type", "Point"]]
        })
        ;

        // Add Marker Open Listener
        showPopup("marker", sourceName);
        // Add Interview Markers
        jsonResponse.features.map(marker => {
          const id = marker.id;
          const idName = `marker_${id}`;


          // map.addLayer({
          //   id: idName,
          //   type: "circle",
          //   source: sourceName,
          //   minzoom: 8.5,
          //   paint: {
          //     "circle-radius": [
          //       'case',
          //       ['boolean', ['feature-state', 'hover'], false],
          //       10, // on hover
          //       6 // default
          //     ],
          //     "circle-color": [
          //       "case",
          //       ["==", ['get', 'published'], true], "#B42222",
          //       ["==", ['get', 'published'], false], "#B07070",
          //       "#B42222",
          //     ],
          //     "circle-radius-transition": { duration: 3000 },
          //   },
          //
          //   transition: {
          //     "duration": 3000,
          //     "delay": 0
          //   },
          //   filter: ["all", ["!=", "category", "areaLabel"], ["==", "$type", "Point"]]
          // });

        });

      };
      req.send(null);
    })


  }
};

export const addAllItems = (firstSymbolId) => {
  const addItems = (map, id, fn) => fn(map, id);

  if (mapType !== "static") {

    // Add Areas
    addItems(map, firstSymbolId, addAreas);

    // Add Lines
    addItems(map, firstSymbolId, addLines);
  } else {
    // Remove all Labels on Static Map
    map.style.stylesheet.layers.forEach(function (layer) {
      if (layer.type === 'symbol') {
        map.removeLayer(layer.id);
      }
    });
  }

  // Add Markers
  addItems(map, firstSymbolId, addMarkers);

}
