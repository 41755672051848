import mapboxgl from 'mapbox-gl';
import styleJson from "../../../mapbox/style.json"
import "@mapbox/mapbox-gl-style-spec";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWF5YmFjaDM5MSIsImEiOiJjazF3OTlsZzUwM2ptM2NxcHFhZmh1bnM1In0.ZMej83VqetLFmXpyPc8COw";

export let map;
export let mapType;
if (map === undefined) {

  if (document.getElementById('map')) {
    mapType = "full";
    const mapConfig = {
      container: "map",
      style: styleJson,
      center: [13.3613499, 52.5173939], // starting position
      fitBoundsOptions: { padding: { top: 10, bottom: 10, left: 10, right: 10 } },
      bounds: [[12.866965134375846, 52.328964279249135], [13.855734665625818, 52.70501886648603]], //set bounds to northeast + southwest point (map.getBounds().getNorthEast())
      maxBounds: [[12.866965134375846, 52.328964279249135], [13.855734665625818, 52.70501886648603]], //set bounds to northeast + southwest point (map.getBounds().getNorthEast())
      zoom: 10, // starting zoom,
      maxZoom: 15,
      minZoom: 10,
    }
    map = new mapboxgl.Map(mapConfig);

    const mq = window.matchMedia('(max-width: 700px)');
    if (mq.matches) {
      map.setZoom(7);
    }

  } else if (document.getElementById('static-map')) {
    mapType = "static";
    const staticConfig = {
      container: "static-map",
      style: styleJson,
      zoom: 11, // starting zoom,
      center: [13.3613499, 52.5173939], // starting position
      //fitBoundsOptions: { padding: { top: 10, bottom: 10, left: 10, right: 10 } },
      //bounds: [[12.866965134375846, 52.328964279249135], [13.855734665625818, 52.70501886648603]],
      //maxBounds: [[12.866965134375846, 52.328964279249135], [13.855734665625818, 52.70501886648603]], //set bounds to northeast + southwest point
      minZoom: 8,
      maxZoom: 15,
      attributionControl: false,
      interactive: false
    }
    map = new mapboxgl.Map(staticConfig);
    map.addControl(new mapboxgl.AttributionControl({
      compact: true
    }))

  }
} else {
  map = window.map;
}
